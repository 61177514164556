import { observer } from 'mobx-react-lite';

import { ExploreSVG, Separator } from '@foundationPathAlias/main';
import { ScrollToTopButton } from '@mainApp/src/components/common';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { useTranslation } from 'react-i18next';
import { AllCommunitiesSection } from './AllCommunitiesSection';
import { FeaturedCommunitiesSection } from './FeaturedCommunitiesSection';
import { Header } from './Header';

type Props = {
  /** on the website explore communities shouldn't be header */
  isWebsiteMode?: boolean;
};

// TODO: add module suffix to all the entry points of modules
export function _ExploreModule(props: Props) {
  const { isWebsiteMode } = props;
  const {
    dimensionsStore: { isMobile },
    systemStore,
  } = useMultipleInjection([
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.systemStore,
  ]);

  const { t } = useTranslation('common');

  return (
    <div className="md:bg-surface-mapping-base-secondary">
      {!isWebsiteMode && (
        <Header
          isMobile={isMobile}
          onHambugerClick={() => {
            systemStore.setIsSidebarOpened(true);
          }}
          label={t('explore')}
          Icon={ExploreSVG}
        />
      )}

      <div className="px-[24px] pb-[48px] md:px-[48px] md:pb-[64px]">
        <FeaturedCommunitiesSection />
        <Separator cn="mt-[48px] md:mt-[64px]" />
        <AllCommunitiesSection isWebsiteMode={isWebsiteMode} />
      </div>
      <ScrollToTopButton btnText={t<string>('backToTop')} />
    </div>
  );
}

export const ExploreModule = observer(_ExploreModule);
