import { ButtonBase } from '@foundationPathAlias/main';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

type RuleItemProps = {
  title: string;
  description: string;
  onClick: () => void;
};

export const RuleItem = ({ title, description, onClick }: RuleItemProps) => {
  return (
    <ButtonBase
      onClick={onClick}
      cn="text-start w-full justify-between py-[16px] px-[12px] hover:bg-buttonsAndIcons-fillColor-transparent-hover flex items-center gap-[16px]"
    >
      <div>
        <h4 className="mb-[4px] text-body16SB text-text-primary">{title}</h4>

        <p className="mb-0 line-clamp-3 text-ellipsis text-sm14R text-text-secondary md:line-clamp-2">
          {description}
        </p>
      </div>

      <ChevronRightIcon
        width={20}
        height={20}
        className="flex-shrink-0 text-element-normal"
      />
    </ButtonBase>
  );
};
