import { useFilePicker } from 'use-file-picker';

import { useEffect } from 'react';

import { IconButton } from '@10x/foundation/src/components';
import { CloudArrowUpIcon } from '@heroicons/react/24/outline';

import { observer } from 'mobx-react-lite';

import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';

// TODO: maybe a dedicated store in the all stores?
import { messageEditorStore } from '../editor-area/MessageEditor.store';

export function _AttachmentsUploadMenu() {
  const attachmentsMediator = useInjection(IOC_TOKENS.attachmentsMediator);

  const [openFileSelector, { filesContent, plainFiles, loading: _loading }] =
    useFilePicker({
      readAs: 'DataURL',
    });

  useEffect(() => {
    // basically the filesContent and plainFiles - reflect themselves
    if (filesContent.length) {
      attachmentsMediator.proceedAttachments(filesContent, plainFiles);

      messageEditorStore.hidePopover();
    }
  }, [filesContent, plainFiles]);

  return (
    <div className="relative z-2 min-w-[326px] rounded-[5px] border-[1px] border-element-subtle bg-baseTheme-background-product py-[8px] shadow-drop-shadow-menu dark:border-element-subtle-dark">
      <IconButton
        cn="border-none"
        contentWrapperElCn="text-text-primary"
        contentLeft={<CloudArrowUpIcon />}
        onClick={() => {
          openFileSelector();
        }}
      >
        Upload file
      </IconButton>
    </div>
  );
}

export const AttachmentsUploadMenu = observer(_AttachmentsUploadMenu);
