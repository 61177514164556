import { IMessageStore } from '@mainApp/src/stores';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  messageStore: IMessageStore;
};
export const HistoryLoadingPanel = forwardRef(
  (props: Props, ref: React.Ref<HTMLDivElement>) => {
    const { messageStore } = props;
    const items = messageStore.items;
    const { t } = useTranslation('common');
    return (
      <div
        className="absolute left-0 top-0 flex h-[50px] w-full items-center justify-center text-center"
        ref={ref}
      >
        <button
          disabled={!items.meta?.pageInfo?.hasPreviousPage || items?.loading}
        >
          {items?.loading || items.meta.pageInfo?.hasPreviousPage
            ? `${t('loadingMore')}...`
            : t('nothingMoreToLoad')}
        </button>
      </div>
    );
  }
);
