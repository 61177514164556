'use client';

import { useEffect } from 'react';
import { RuleHeader } from './components/RuleHeader';
import { useScreenRules } from './ScreenRules.context';

export const Rules = () => {
  const {
    stackNextItem,
    stackPrevItem,
    stackVisibleItem,
    stackState,
    animatedStack,
    currentItem,
  } = useScreenRules();

  useEffect(() => {
    animatedStack.setVisible(currentItem.id, currentItem.Component);
  }, []);

  return (
    <>
      <RuleHeader />

      <div className="relative flex min-h-[_calc(100%-65px)_] w-full flex-1 overflow-hidden">
        {stackPrevItem.Component && (
          <stackPrevItem.Component key={stackPrevItem.id} />
        )}

        {stackVisibleItem.Component && (
          <stackVisibleItem.Component
            key={stackVisibleItem.id}
            startLeaving={stackState.startLeaving}
            onLeaveEnd={() => {
              animatedStack.onLeavingAnimationEnd();
            }}
          />
        )}

        {stackNextItem.Component && (
          <stackNextItem.Component
            isNext={true}
            startEntering={stackState.startEntering}
            key={stackNextItem.id}
            onEnterEnd={() => {
              animatedStack.onEnterAnimationEnd();
            }}
          />
        )}
      </div>
    </>
  );
};
