import { DateSeparatorModel } from './data/DateSeparator.model';

const lineClass =
  'relative overflow-hidden before:absolute before:w-full before:top-[50%] before:h-[1px] before:bg-element-subtle dark:before:bg-element-subtle-dark ';

const leftLineClass = lineClass + ' before:left-[16px]';
const rightLineClass = lineClass + 'before:right-[16px]';

type Props = {
  model: DateSeparatorModel;
};

export function DateSeparatorItem(props: Props) {
  return (
    <div className="grid w-full grid-cols-[1fr_auto_1fr]">
      <div className={leftLineClass} />
      <span className="themed-text-secondary px-[12px] text-sm14T">
        {props.model.date}
      </span>
      <div className={rightLineClass} />
    </div>
  );
}
