const colors = {
  ex: {
    default: {
      redBlue: {
        light: 'red',
        dark: 'blue',
      },
    },
  },
  baseTheme: {
    background: {
      website: {
        light: 'rgba(255, 255, 255, 1)',
        dark: 'rgba(13, 13, 13, 1)',
      },
      product: {
        light: 'rgba(255, 255, 255, 1)',
        dark: 'rgba(13, 13, 13, 1)',
      },
    },
  },
  utilities: {
    zeroOpacity: {
      light: 'rgba(255, 255, 255, 0)',
      dark: 'rgba(255, 255, 255, 0)',
    },
    lightDark: {
      light: 'rgba(255, 255, 255, 1)',
      dark: 'rgba(0, 0, 0, 1)',
    },
    blackWhite: {
      light: 'rgba(0, 0, 0, 1)',
      dark: 'rgba(255, 255, 255, 1)',
    },
    white: {
      light: 'rgba(255, 255, 255, 1)',
      dark: 'rgba(255, 255, 255, 1)',
    },
    transparentLight: {
      light: 'rgba(255, 255, 255, 1)',
      dark: 'rgba(24, 24, 24, 1)',
    },
  },
  states: {
    hover: {
      light: 'rgba(26, 26, 26, 0.05)',
      dark: 'rgba(255, 255, 255, 0.08)',
    },
    active: {
      light: 'rgba(26, 26, 26, 0.05)',
      dark: 'rgba(255, 255, 255, 0.08)',
    },
    pressed: {
      light: 'rgba(26, 26, 26, 0.1)',
      dark: 'rgba(255, 255, 255, 0.1)',
    },
  },
  brand: {
    hundredPercent: {
      light: 'rgba(95, 92, 255, 1)',
      dark: 'rgba(123, 122, 255, 1)',
    },
    hover: { light: 'rgba(120, 117, 255, 1)', dark: 'rgba(148, 148, 255, 1)' },
    pressed: { light: 'rgba(70, 66, 255, 1)', dark: 'rgba(98, 97, 255, 1)' },
    tenPercent: {
      light: 'rgba(95, 92, 255, 0.1)',
      dark: 'rgba(123, 122, 255, 0.1)',
    },
    fortyPercent: {
      light: 'rgba(95, 92, 255, 0.4)',
      dark: 'rgba(123, 122, 255, 0.4)',
    },
    sixtyPercent: {
      light: 'rgba(95, 92, 255, 0.6)',
      dark: 'rgba(123, 122, 255, 0.6)',
    },
    eightyPercent: {
      light: 'rgba(95, 92, 255, 0.8)',
      dark: 'rgba(123, 122, 255, 0.8)',
    },
  },
  surface: {
    overlay: {
      default: {
        light: 'rgba(26, 26, 26, 0.55)',
        dark: 'rgba(51, 51, 51, 0.65)',
      },
      subtle: {
        light: 'rgba(26, 26, 26, 0.25)',
        dark: 'rgba(51, 51, 51, 0.35)',
      },
    },
    onBase: {
      secondary: {
        light: 'rgba(0, 0, 0, 0.03)',
        dark: 'rgba(255, 255, 255, 0.04)',
      },
      tertiary: {
        light: 'rgba(0, 0, 0, 0.07)',
        dark: 'rgba(255, 255, 255, 0.08)',
      },
      quaternary: {
        light: 'rgba(0, 0, 0, 0.11)',
        dark: 'rgba(255, 255, 255, 0.14)',
      },
    },
    onSecondary: {
      primary: {
        light: 'rgba(255, 255, 255, 1)',
        dark: 'rgba(0, 0, 0, 1)',
      },
      tertiary: {
        light: 'rgba(0, 0, 0, 0.04)',
        dark: 'rgba(255, 255, 255, 0.05)',
      },
      quaternary: {
        light: 'rgba(0, 0, 0, 0.08)',
        dark: 'rgba(255, 255, 255, 0.1)',
      },
    },
    onTertiary: {
      primary: {
        light: 'rgba(255, 255, 255, 1)',
        dark: 'rgba(0, 0, 0, 1)',
      },
      secondary: {
        light: 'rgba(255, 255, 255, 0.55)',
        dark: 'rgba(0, 0, 0, 0.32)',
      },
      quaternary: {
        light: 'rgba(0, 0, 0, 0.04)',
        dark: 'rgba(255, 255, 255, 0.05)',
      },
    },
    onQuaternary: {
      primary: {
        get light() {
          return colors.baseTheme.background.product.light;
        },
        get dark() {
          return colors.baseTheme.background.product.dark;
        },
      },
      secondary: {
        light: 'rgba(255, 255, 255, 0.7)',
        dark: 'rgba(0, 0, 0, 0.52)',
      },
      tertiary: {
        light: 'rgba(255, 255, 255, 0.37)',
        dark: 'rgba(0, 0, 0, 0.31)',
      },
    },
    overMedia: {
      default: {
        light: 'rgba(255, 255, 255, 0.45)',
        dark: 'rgba(12, 12, 12, 0.45)',
      },
      strongContrast: {
        light: 'rgba(255, 255, 255, 0.65)',
        dark: 'rgba(12, 12, 12, 0.65)',
      },
      extraStrongContrast: {
        light: 'rgba(255, 255, 255, 0.8)',
        dark: 'rgba(12, 12, 12, 0.7)',
      },
      ultraStrongContrast: {
        light: 'rgba(255, 255, 255, 0.92)',
        dark: 'rgba(12, 12, 12, 0.92)',
      },
      alwaysDarken: {
        light: 'rgba(0, 0, 0, 0.6)',
        dark: 'rgba(0, 0, 0, 0.6)',
      },
    },
    mapping: {
      base: {
        tertiary: {
          get light() {
            return colors.baseTheme.background.product.light;
          },
          get dark() {
            return colors.surface.onBase.tertiary.dark;
          },
        },
        secondary: {
          get light() {
            return colors.baseTheme.background.product.light;
          },
          get dark() {
            return colors.surface.onBase.secondary.dark;
          },
        },
        quaternary: {
          get light() {
            return colors.baseTheme.background.product.light;
          },
          get dark() {
            return colors.surface.onTertiary.quaternary.dark;
          },
        },
      },
      tertiary: {
        base: {
          get light() {
            return colors.surface.onBase.tertiary.light;
          },
          get dark() {
            return colors.baseTheme.background.product.dark;
          },
        },
      },
      secondary: {
        tertiary: {
          get light() {
            return colors.surface.onBase.secondary.light;
          },
          get dark() {
            return colors.surface.onBase.tertiary.dark;
          },
        },
        secondary: {
          get light() {
            return colors.surface.onBase.secondary.light;
          },
          get dark() {
            return colors.surface.onTertiary.secondary.dark;
          },
        },
        base: {
          get light() {
            return colors.surface.onBase.secondary.light;
          },
          get dark() {
            return colors.baseTheme.background.product.dark;
          },
        },
      },
    },
  },
  element: {
    subtle: {
      light: 'rgba(0, 0, 0, 0.1)',
      dark: 'rgba(255, 255, 255, 0.1)',
    },
    normal: {
      light: 'rgba(0, 0, 0, 0.19)',
      dark: 'rgba(255, 255, 255, 0.2)',
    },
    disabled: {
      light: 'rgba(0, 0, 0, 0.14)',
      dark: 'rgba(255, 255, 255, 0.16)',
    },
    warning: {
      light: 'rgba(217, 124, 18, 1)',
      dark: 'rgba(250, 146, 71, 1)',
    },
    warningDim: {
      light: 'rgba(217, 124, 18, 0.1)',
      dark: 'rgba(250, 146, 71, 0.1)',
    },
    success: {
      success: {
        light: 'rgba(23, 135, 82, 1)',
        dark: 'rgba(65, 217, 162, 1)',
      },
      successDim: {
        light: 'rgba(23, 135, 82, 0.1)',
        dark: 'rgba(65, 217, 162, 0.1)',
      },
    },
    error: {
      error: {
        light: 'rgba(219, 52, 62, 1)',
        dark: 'rgba(255, 99, 108, 1)',
      },
      errorDim: {
        light: 'rgba(219, 52, 62, 0.1)',
        dark: 'rgba(255, 99, 108, 0.1)',
      },
    },
  },
  text: {
    primary: {
      light: 'rgba(26, 26, 26, 1)',
      dark: 'rgba(255, 255, 255, 0.9)',
    },
    primaryDark: {
      dark: 'rgba(26, 26, 26, 1)',
      light: 'rgba(255, 255, 255, 0.9)',
    },
    onBrand: {
      light: 'rgba(255, 255, 255, 1)',
      dark: 'rgba(255, 255, 255, 1)',
    },
    secondary: {
      light: 'rgba(26, 26, 26, 0.6)',
      dark: 'rgba(255, 255, 255, 0.65)',
    },
    disabled: {
      light: 'rgba(26, 26, 26, 0.4)',
      dark: 'rgba(255, 255, 255, 0.4)',
    },
  },
  buttonsAndIcons: {
    fillColor: {
      transparent: {
        default: {
          get light() {
            return colors.utilities.zeroOpacity.light;
          },
          get dark() {
            return colors.utilities.zeroOpacity.dark;
          },
        },
        hover: {
          get light() {
            return colors.states.hover.light;
          },
          get dark() {
            return colors.states.hover.dark;
          },
        },
        pressed: {
          get light() {
            return colors.states.pressed.light;
          },
          get dark() {
            return colors.states.pressed.dark;
          },
        },
        activeSelected: {
          get light() {
            return colors.states.active.light;
          },
          get dark() {
            return colors.states.active.dark;
          },
        },
      },
      secondary: {
        default: {
          get light() {
            return colors.surface.onBase.secondary.light;
          },
          get dark() {
            return colors.surface.onBase.secondary.dark;
          },
        },
        hover: {
          get light() {
            return colors.states.hover.light;
          },
          get dark() {
            return colors.states.hover.dark;
          },
        },
        pressed: {
          get light() {
            return colors.states.pressed.light;
          },
          get dark() {
            return colors.states.pressed.dark;
          },
        },
        activeSelected: {
          get light() {
            return colors.states.active.light;
          },
          get dark() {
            return colors.states.active.dark;
          },
        },
      },
      primary: {
        default: {
          get light() {
            return colors.brand.hundredPercent.light;
          },
          get dark() {
            return colors.brand.hundredPercent.dark;
          },
        },
        hover: {
          get light() {
            return colors.brand.hover.light;
          },
          get dark() {
            return colors.brand.hover.dark;
          },
        },
        pressed: {
          get light() {
            return colors.brand.pressed.light;
          },
          get dark() {
            return colors.brand.pressed.dark;
          },
        },
        activeSelected: {
          get light() {
            return colors.brand.hover.light;
          },
          get dark() {
            return colors.brand.hover.dark;
          },
        },
      },
    },
    iconColor: {
      onSecondary: {
        default: {
          get light() {
            return colors.text.secondary.light;
          },
          get dark() {
            return colors.text.secondary.dark;
          },
        },
        hover: {
          get light() {
            return colors.text.primary.light;
          },
          get dark() {
            return colors.text.primary.dark;
          },
        },
        pressed: {
          get light() {
            return colors.text.primary.light;
          },
          get dark() {
            return colors.text.primary.dark;
          },
        },
        activeSelected: {
          get light() {
            return colors.text.primary.light;
          },
          get dark() {
            return colors.text.primary.dark;
          },
        },
      },
      onPrimary: {
        default: {
          get light() {
            return colors.text.onBrand.light;
          },
          get dark() {
            return colors.text.onBrand.dark;
          },
        },
        hover: {
          get light() {
            return colors.text.onBrand.light;
          },
          get dark() {
            return colors.text.onBrand.dark;
          },
        },
        pressed: {
          get light() {
            return colors.text.onBrand.light;
          },
          get dark() {
            return colors.text.onBrand.dark;
          },
        },
        activeSelected: {
          get light() {
            return colors.text.onBrand.light;
          },
          get dark() {
            return colors.text.onBrand.dark;
          },
        },
      },
    },
    textColor: {
      onSecondary: {
        default: {
          get light() {
            return colors.text.secondary.light;
          },
          get dark() {
            return colors.text.secondary.dark;
          },
        },
        hover: {
          get light() {
            return colors.text.primary.light;
          },
          get dark() {
            return colors.text.primary.dark;
          },
        },
        pressed: {
          get light() {
            return colors.text.primary.light;
          },
          get dark() {
            return colors.text.primary.dark;
          },
        },
        activeSelected: {
          get light() {
            return colors.text.primary.light;
          },
          get dark() {
            return colors.text.primary.dark;
          },
        },
      },
      onPrimary: {
        default: {
          get light() {
            return colors.text.onBrand.light;
          },
          get dark() {
            return colors.text.onBrand.dark;
          },
        },
        hover: {
          get light() {
            return colors.text.onBrand.light;
          },
          get dark() {
            return colors.text.onBrand.dark;
          },
        },
        pressed: {
          get light() {
            return colors.text.onBrand.light;
          },
          get dark() {
            return colors.text.onBrand.dark;
          },
        },
        activeSelected: {
          get light() {
            return colors.text.onBrand.light;
          },
          get dark() {
            return colors.text.onBrand.dark;
          },
        },
      },
    },
  },
};

module.exports = colors;
