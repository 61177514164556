import { Channel_Types } from '@10x/foundation/types/graphql-schema';

import { RulesSVG } from '@foundationPathAlias/components/svg';

import { ChatBubbleLeftIcon, HashtagIcon } from '@heroicons/react/24/outline';

const iconsRegistry: any = {
  [Channel_Types.Messages]: ChatBubbleLeftIcon,
  [Channel_Types.Feeds]: HashtagIcon,
  [Channel_Types.Rules]: RulesSVG,
};

export function getChannelIconByType(channelType: Channel_Types) {
  if (!iconsRegistry[channelType]) {
    throw new Error(`There is no such channel type icon. Got: ${channelType}`);
  }

  return iconsRegistry[channelType];
}
