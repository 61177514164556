import { useEffect } from 'react';

import { observer } from 'mobx-react-lite';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';

import { NoSsrWrapper } from '@mainApp/src/components/common';
import { DropupManager } from '@mainApp/src/modules/channel/common';
import { DropupTypeEnum } from '@mainApp/src/modules/channel/common/types';
import { MessageEditor } from '@mainApp/src/modules/channel/editor-area/MessageEditor';

import { MessageAttachments } from '../attachments/MessageAttachments';

import { classNames } from '@foundationPathAlias/utilities';
import { EditorInfoBar } from './EditorInfoBar';

export type MessageEditorSubmitCb = (text: string, stateInJson: string) => void;

type MessageEditorWrapperProps = {
  onCreateMessage: MessageEditorSubmitCb;
  onEditMessage: MessageEditorSubmitCb;
};

function _EditorWrapper(props: MessageEditorWrapperProps) {
  const { onCreateMessage, onEditMessage } = props;
  const { channelStore, messageStore, communityStore } = useMultipleInjection([
    IOC_TOKENS.channelStore,
    IOC_TOKENS.messageStore,
    IOC_TOKENS.communityStore,
  ]);

  useEffect(() => {
    // should create an empty message model for the editor
    // to keep there attachments, text etc.

    messageStore.setActiveNewEmptyMessageModel();
  }, []);

  const isEditing = messageStore?.editData?.active;
  const isReplyOpen = isEditing || messageStore?.replyModel;

  return (
    <NoSsrWrapper>
      <div>
        <EditorInfoBar />
        <div
          className={classNames(
            'flex w-full flex-col overflow-hidden rounded-[5px] border-[1px] border-solid border-element-subtle dark:border-element-subtle-dark',
            isReplyOpen && 'rounded-t-none'
          )}
        >
          <DropupManager />
          <div className="relative bg-surface-mapping-base-quaternary px-[15px] ">
            <MessageAttachments />

            <MessageEditor
              onMentionQueryChange={(data) => {
                const { type, query } = data;

                const activeCommunityId =
                  channelStore.activeChannel.data?.serverData.communityId;
                if (!activeCommunityId) return;

                if (type === DropupTypeEnum.SUGGESTION_USERS) {
                  communityStore.getCommunityMembers({
                    communityId: activeCommunityId,

                    search: query,
                  });
                } else if (type === DropupTypeEnum.SUGGESTION_CHANNELS) {
                  channelStore.setChannelSearchCriteria(query);
                }
              }}
              onSubmit={(text: string, stateInJson: object) => {
                const stringifiedEditorState = JSON.stringify(stateInJson);

                if (messageStore.editData.active) {
                  onEditMessage(text, stringifiedEditorState);
                } else {
                  onCreateMessage(text, stringifiedEditorState);
                }

                return;
              }}
            />
          </div>
        </div>
      </div>
    </NoSsrWrapper>
  );
}

export const EditorWrapper = observer(_EditorWrapper);
