import { SquareIconButtonBase } from '@foundationPathAlias/main';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { NoSsrWrapper } from '@mainApp/src/components/common';

import { Separator } from '@foundationPathAlias/main';
import { AuthPanel } from '@mainApp/src/components/navbar/AuthPanel';

type HeaderPropsType = {
  isMobile: boolean;
  onHambugerClick: () => void;
  Icon: React.FC<any>;
  label: string;
};

export function Header(props: HeaderPropsType) {
  const { isMobile, onHambugerClick, Icon, label } = props;

  const labelWithIcon = (
    <div className="flex items-center">
      {Boolean(Icon) && (
        <Icon className="mr-[8px] mt-[2px] h-[20px] w-[20px] fill-text-primary" />
      )}
      <h1 className="text-primary text-subheading">{label}</h1>
    </div>
  );

  const content = isMobile ? (
    <>
      <div className="relative flex flex-1 justify-center">
        <SquareIconButtonBase
          className="absolute left-0"
          onClick={onHambugerClick}
        >
          <Bars3Icon className="text-iconColor-onSecondary-default" />
        </SquareIconButtonBase>

        {labelWithIcon}
      </div>
    </>
  ) : (
    <div>{labelWithIcon}</div>
  );

  return (
    <NoSsrWrapper>
      <div>
        <div className="relative flex items-center justify-center space-x-[6px] px-[16px] py-[9px]">
          {content}
          <div className="absolute right-[20px]">
            <AuthPanel />
          </div>
        </div>
        <Separator />
      </div>
    </NoSsrWrapper>
  );
}
