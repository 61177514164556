import { classNames } from '@10x/foundation/src/utilities';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/20/solid';

type Props = {
  className?: string;
  showMobileNav: boolean;
  onShow: () => void;
  onHide: () => void;
};

export function HamburgerBtn({
  className,
  showMobileNav,
  onShow,
  onHide,
}: Props) {
  return (
    <>
      <button
        aria-label="Open menu"
        id="cy-hamburger-btn"
        className={classNames(
          'hover-el h-[22px] w-[22px]',
          showMobileNav ? 'hidden' : '',
          className
        )}
        onClick={onShow}
      >
        <Bars3Icon className="themed-svg" />
      </button>
      <button
        aria-label="Close menu"
        id="cy-hamburger-close-btn"
        className={classNames(
          'hover-el h-[22px] w-[22px]',
          !showMobileNav ? 'hidden' : '',
          className
        )}
        onClick={onHide}
      >
        <XMarkIcon className="themed-svg" />
      </button>
    </>
  );
}
