import { ChannelGroupLoader } from './ChannelGroupLoader';

export function ChannelGroupListLoader() {
  return (
    <div className="mx-[8px]">
      {[...Array(3)].map((_, index) => {
        return (
          <div className="my-[12px]" key={'ChannelGroupLoader-' + index}>
            <ChannelGroupLoader uniqueKey={'ChannelGroupLoader-' + index} />
          </div>
        );
      })}
    </div>
  );
}
