import dynamic from 'next/dynamic';

// TODO: What is the point of SSR ant Next.js overall if we have all the dynamic imports due to hydration errors?
const HomeSection1 = dynamic(
  () => import('./HomeSection1').then((module) => module.HomeSection1),
  { ssr: false }
);

const Carousel = dynamic(
  () => import('./carousel/Carousel').then((module) => module.Carousel),
  { ssr: false }
);
const HomeSection3 = dynamic(
  () =>
    import('./section-3/HomeSection3').then((module) => module.HomeSection3),
  { ssr: false }
);
const HomeSection4 = dynamic(
  () =>
    import('./section-4/HomeSection4').then((module) => module.HomeSection4),
  { ssr: false }
);
const ReadyToStartBlock = dynamic(
  () =>
    import('./ReadyToStartBlock').then((module) => module.ReadyToStartBlock),
  { ssr: false }
);

export function Home() {
  return (
    <div className="bg-background-primary dark:bg-background-website-dark">
      <HomeSection1 />
      <Carousel />
      <HomeSection3 />
      <HomeSection4 />
      <ReadyToStartBlock />
    </div>
  );
}
